body {
  font-family: Inter, tahoma, sans-serif;
  color: #fff;
}

.app {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  background: #33343B;;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);
}

.landing-page__content {
  max-width: 1440px;
  margin: 0 auto;
}

@media screen and (min-width: 1461px){
  .content-max-width {
    width: 85%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 1460px){
  .content-max-width {
    max-width: 1200px;
    width: 85%;
    margin: 0 auto;
  }
  .app {
    margin: 0 auto;
  }
}
