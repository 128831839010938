.fact__text {
    margin-left: 10px;
}
.fact__text-box__text {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    opacity: 0.5;
}
.fact__text-box {
    padding: 23px 21px ;
}
.fact__icon__scale {
    width: 56px;
    height: 56px;
}
@media screen and (min-width: 1441px){
    .fact__text-box__text {
        font-size: 18px;
    }
}
@media screen and (max-width: 767px){
    .fact__text-box__text {
        font-size: 14px;
        line-height: 17px;
    }
    .fact__icon__scale {
        width: 48px;
        height: 48px;
    }
}