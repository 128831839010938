.personnel-card {
    width: 260px;
    height: 439px;
    background: radial-gradient(137.01% 137.01% at 90.28% 105.51%, #292A30 0%, #292A30 95.01%);
    box-shadow: -6px -6px 20px rgba(255, 255, 255, 0.03), 7px 7px 22px rgba(0, 0, 0, 0.27), inset 0px 4px 4px rgba(255, 255, 255, 0.09);
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 25px;
    user-select: none;
}

.personnel-card__picture img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 4px solid #33343F;
}

.personnel-card__picture {
    padding: 1px;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    box-sizing: initial;
    background: linear-gradient(159.39deg, #2CCBC2 13.66%, rgba(44, 203, 194, 0) 103.05%);
    box-shadow: -3px -1px 4px rgba(255, 255, 255, 0.1), 2px 4px 10px rgba(0, 0, 0, 0.26);
    margin: 0 auto;
}

.personnel-card__name {
    text-align: center;
    margin: 23px 0;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    opacity: 0.8;
}
.personnel-card__background {
    font-size: 14px;
    line-height: 24px;
    opacity: 0.5;
}

.circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 11px;
    height: 11px;
    margin-right: 5px;
    border-radius: 50%;
    background: linear-gradient(202.41deg, #2CCBC2 14.6%, #16716C 115.05%);
    opacity: 0.47;
    box-shadow: -2px 0px 3px rgba(255, 255, 255, 0.05), 1px 1px 7px rgba(0, 0, 0, 0.26);
}

@media screen and (max-width: 767px){
    .personnel-card {
        margin: 20px auto 0;
        width: 90%;
        max-width: 296px;
    }
}