.social-media {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    max-width: 300px;
    padding-right: 30px;
}

@media screen and (max-width: 767px){
    .social-media > div {
        margin: 0 auto;
    }
}