.security {
    margin-bottom: 227px;
}

.security__image {
    width: 95%;
    margin: 0 auto;
}

@media screen and (max-width: 1199px){
    .security__image {
        margin: 30px auto;
    }
}

@media screen and (max-width: 767px){
    .security__image-card {
        float: right!important;
        margin: 30px auto;
    }
    .security {
        margin-bottom: 100px;
    }
}