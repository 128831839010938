.solution-card {
    max-width: 360px;
    width: 100%;
    height: 407px;
    padding: 40px;
    margin: 0 auto;
}

.solution-card h4 {
    text-align: center;
    margin: 30px 0 20px;
    font-size: 26px;
    line-height: 31px;
}

.solution-card__text {
    font-size: 16px;
    line-height: 22px;
    text-align: justify;
    letter-spacing: -0.03em;
    opacity: 0.8;
}

@media screen and (min-width: 1441px) {
    .solution-card {
        max-width: 90%;
        margin: 0 auto;
    }
    .solution-card__text {
        font-size: 1vw;

    }
}
@media screen and (max-width: 1300px) {
    .solution-card {
        width: 95%;
        padding: 40px 20px;
        height: 360px;
    }
}
@media screen and (max-width: 1199px) {

    .solution-card__text {
        font-size: 14px;
        text-align: left;
    }
    .solution-card h4 {
        font-size: 18px;
        line-height: 22px;
    }
}