.intro-art__depth {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    max-width: 800px;
    width: 100%;
}

.intro-art__depth img {
    position: relative;
    z-index: 2;
    width: 100%;
}

.intro-art__depth__fill {
    position: absolute!important;
    right: 0;
    top: 0;
    z-index: 0!important;
}

.intro-art__floating-circle {
    position: absolute;
    width: 68px;
    height: 68px;
    right: 0;
    top: 0;
    box-shadow: inset -7px -3px 18px rgba(255, 255, 255, .49), inset 10px 6px 18px rgba(0, 25, 64, .65);
}

.intro-art__float-orbit {
    position: absolute;
    z-index: 1;
    right: 100px;
    top: 300px;
    width: 365px;
    height: 300px;
    animation: orbit 10s linear infinite;
}

@keyframes orbit {
    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 1199px){
    .intro-art__depth {
        max-width: 730px;
    }
}

@media screen and (max-width: 767px){
    .intro-art__depth {
        max-width: 500px;
    }
    .intro-art__float-orbit {
        width: 150px;
        height: 290px;
        right: 90px;
        top: 90px;
    }
    .intro-art__floating-circle {
        width: 46px;
        height: 46px;
    }
}

@media screen and (max-width: 420px){
    .intro-art__depth {
        max-width: 400px;
    }
    .intro-art__float-orbit {
        width: 150px;
        height: 180px;
        right: 70px;
        top: 135px;
    }
    .intro-art__floating-circle {
        width: 38px;
        height: 38px;
    }
}
@media screen and (max-width: 350px){
    .intro-art__depth {
        max-width: 320px;
    }
    .intro-art__float-orbit {
        width: 200px;
        height: 98px;
        right: 25px;
        top: 135px;
    }
}