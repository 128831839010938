.statistic {
    max-width: 570px;
    width: 100%;
}
.statistic__box {
    padding: 0 5px;
}
.statistic__text {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    opacity: 0.5;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statistic__figure {
    height: 60px;
    background: linear-gradient(30deg, #2CCBC2 0%, #1D46D6 100%);
    box-shadow: -5px -5px 15px rgba(143, 201, 255, 0.13), 10px 10px 20px rgba(0, 0, 0, 0.26);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.statistic__figure__border-wrap {
    background: linear-gradient(-30deg, #2CCBC2 0%, #1D46D6 100%);
    padding: 1px;
    border-radius: 16px;
}

.statistic__figure span {
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    opacity: 0.5;
}

.statistic__figure img {
    max-width: 150px;
    width: 50%;
    margin-right: 10px;
}

@media screen and (max-width: 1199px){
    .statistic, .statistic__figure img {
        max-width: initial;
    }

}