.pressed-text-box {
    background: #262729;
    box-shadow: inset 6px 7px 8px rgba(0, 0, 0, 0.22);
    border-radius: 18px;
}
.pressed-text-box-2 {
    background: #262729;
    box-shadow: inset -7px -3px 20px rgba(255, 255, 255, 0.1), inset 10px 12px 16px rgba(0, 0, 0, 0.22);
    border-radius: 18px;
}
.pressed-text-box-3 {
    background: linear-gradient(180deg, #26272C 0%, #313338 100%);
    box-shadow: -6px -6px 9px rgba(255, 255, 255, 0.03), 7px 7px 16px rgba(0, 0, 0, 0.21), inset 0px 4px 2px rgba(255, 255, 255, 0.06);
    border-radius: 18px;
}