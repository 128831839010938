.financial-empowerment {
    position: relative;
    margin-bottom: 153px;
}
.financial-empowerment__desc {
    max-width: 616px;
    width: 100%;
}
.financial-empowerment__text {
    font-size: 18px;
    line-height: 22px;
    text-align: justify;
    letter-spacing: -0.03em;
    opacity: 0.8;
}
.financial-empowerment img {
    width: 60%;
    max-width: 980px;
    position: absolute;
    top: -100px;
    right: -160px;
    z-index: 1;
}

.financial-empowerment__shine {
    right: -200px;
    bottom: -100px;
    background: rgba(29, 187, 255, 0.5);
}
.financial-empowerment__shine-2 {
    left: -200px;
    bottom: -100px;
    background: rgba(29, 187, 255, 0.5);
    filter: blur(100px) !important;
}

@media screen and (max-width: 1199px){
    .financial-empowerment__text {
        font-size: 14px;
        line-height: 17px;
    }
    .financial-empowerment__desc {
        max-width: 400px;
    }
}

@media screen and (max-width: 767px){
    .financial-empowerment img {
        position: relative;
        width: 100%;
        top: initial;
        right: -40px;
    }
}